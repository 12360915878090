<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">

            <v-btn color="success" @click="create()">
              Add new
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>
          <strong><v-icon x-large>mdi-parking</v-icon></strong> Car Parks
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="carParks"
            item-key="name"
            show-select
            class="elevation-1 table-one"
            multi-sort
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.occupancy="{item}">
            <div class="d-flex">

              <template>
                <v-progress-linear
                    :value="item.occupancy"
                    width="50"
                    height="10"
                    striped
                    color="deep-orange"
                />
              </template>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      color="success"
                      dark
                      @click="edit(item.id)"
                      icon
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      color="danger"
                      dark
                      icon
                      @click="remove(item.id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>

import toastr from "toastr";
import {mapActions, mapGetters, mapState} from "vuex";


export default {
  name: "carpark-list",
  metaInfo: {
    title: 'Car Parks'
  },
  data() {
    return {
      search: '',
      selected: [],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          align: 'left',
        },
        {
          text: 'Capacity',
          sortable: true,
          value: 'capacity',
          align: 'left',
        },
        {
          text: 'Occupancy',
          sortable: true,
          value: 'occupancy',
          align: 'left',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('car-parks', {loading : 'isFindPending'}),
    ...mapGetters('car-parks', {findCarParksInStore : 'find'}),

    carParks() {
      return this.findCarParksInStore().data || [];
    }
  },
  methods: {
    ...mapActions('car-parks', { findCarParks: 'find' }),

    create() {
      this.$router.push({name: 'carpark-create'})
    },
    edit(id) {
      this.$router.push({name: 'carpark-edit', params: { id: id }})
    },
    view(id) {
      this.$router.push({name: 'carpark-view', params: { id: id }})
    },
    remove(id) {
      console.log(`Trying to remove ${id}`)
      this.$store.dispatch('car-parks/remove', id).then((result) => {
        toastr.success('Successfully removed a car park.', 'Success')
      }).catch((error) => {// Display an error toast, with a title
        toastr.error('Failed to remove the selected car park', 'Error')
        console.error(error);
      })
    },
  },
  mounted() {
    this.findCarParks({ query : {} });
  },
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
